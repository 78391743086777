/* FeedbackForm.css */

.feedback-form {
  position: fixed;
  right: 10px;
  bottom: 20px;
  z-index: 999;
}

.feedback-icon {
  right: 0px;
  bottom: 0px;
  position: relative;
  background-color: transparent;
  cursor: pointer;
}

.feedback-icon img {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 767px) {
  .flower-icon {
    width: 30px;
    height: 30px;
  }
}

.feedback-form-content {
  position: relative;
  background-color: #fff;
  border: 5px solid #F7F1E5;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 10px;
  z-index: 998;
}

@media (max-width: 576px) {
.feedback-form {
  right:0;
  width: 100%;
}

  .feedback-form-content {
    width: 100%;
  }
}

.feedback-form-content h3 {
  margin-top: 0;
}

.feedback-form-content textarea {
  width: 100%;
  height: 120px;
  resize: none;
  margin-bottom: 10px;
}

.feedback-form-content button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
/* Add this style to style the file input container */
.file-input-container {
  position: relative;
  margin-top: 10px;
}

/* Style for the custom file input label */
.custom-file-label {
  display: inline-block;
  padding: 8px 12px;
  border: 2px solid #ccc;
  border-radius: 6px;
  background-color: #f9f9f9;
  color: #555;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

/* Style for the actual file input, make it transparent and cover the label */
.custom-file-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/* Add hover effect on the label */
.custom-file-label:hover {
  background-color: #e9e9e9;
  border-color: #aaa;
  color: #444;
}
.file-preview {
  width: 100%;
  max-width: 100px;
  max-height: 80px;
  margin-top: 10px;
  display: block;
}
/* Add this style for the preview container */
.preview-container {
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

/* Add this style to position the remove button at the bottom right */
#remove-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #af5b5b;
  color: white;
  border: 2px solid transparent;
  padding: 2px 10px;
  border-radius: 11px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

#remove-button:hover {
  background-color: #d32f2f;
}
