.add-product {
  .fileinput {
    .thumbnail {
      max-width: 370px;
      max-height: 250px;
    }
  }
}
.error-500 {
  .container {
    position: relative;
  }
}
