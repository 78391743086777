
.input-field-template {
    border-radius: 3px;
    border: 3px solid #000000 !important;
    width: 100%;
    box-shadow: none;
    margin-bottom: 5px;
  }
  
  .input-field-template-dark {
    width: 100%;
    box-shadow: none;
    margin-bottom: 5px;
  }
  
  .input-field-template-dark .form-control,
  .input-field-template-dark .input-group-text,
  .input-field-template-dark select,
  .input-field-template-dark .rdt {
    border-radius: 5px;
    background-color: #f7f1e5 !important;
  }
  
  .input-field-template-dark .rdt {
    width: 100%;
  }
  
  .input-field-template::placeholder,
  .input-field-template-dark::placeholder {
    padding: 8px;
    font-size: 12px;
  }
  .input-field-template input::placeholder {
    padding: 0;
    font-size: 12px;
  }

  .input-field-template-dark select,
  .input-field-template-dark .rdt input{
    color:grey
  }

.hrefLink:hover {
text-decoration: underline;
}

.rdtPicker {
  top: auto !important;
  bottom: 45px !important;
}

.rdtPicker:after, .rdtPicker:before{
  border-top: 0.4em solid #FFFFFF !important;
  border-bottom: 0 !important;
  top:auto!important;
  bottom: -5px!important;
}
  