.headroom {
  transition: top 0.5s ease-in-out;
  display: block;
}
.headroom--pinned {
  position: relative;
  top: 0;
  width: 100%;
}
.headroom--unpinned {
  top: -100px;
  position: relative;
  width: 100%;
}
